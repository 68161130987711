export function occasionsMenu(occasions, showEnPrimeur = true) {
  const menu = {
    text: 'Collections',
    meta: ['menu-mega', 'inline'],
    items: occasions
      ? occasions.map((category) => ({
          text: category.title || 'Featured',
          items: category.items.map((item) => ({
            text: item.name,
            url: `/store/occasion/${item.slug}/`,
          })),
        }))
      : [],
  }
  if (occasions && showEnPrimeur) {
    menu.items[0].items.push({
      text: 'En Primeur',
      meta: ['en-primeur'],
      url: '/en-primeur/',
    })
  }
  return menu
}

export function giftingItemsMenu(giftingItems, isMobile) {
  const menuItems = []
  giftingItems.forEach((giftItem) => {
    const item = {
      text: giftItem.name,
      url: giftItem.url,
    }
    if (isMobile) {
      item.meta = ['menu-secondary']
    }
    menuItems.push(item)
  })
  return menuItems
}

export function mobileOccasionsMenu(occasions, showEnPrimeur = true) {
  // This is a flatter and differently styled occasions menu for the mobile nav
  const occasionsMenuItems = []
  const theCategoryImageMap = {
    store: 'mobilenav-featuredwines',
    wineteam: 'mobilenav-expert',
    restaurant: 'mobilenav-restaurant',
  }
  occasions.forEach((category) => {
    const theCategory = category.title || 'Featured'
    occasionsMenuItems.push({
      text: theCategory,
      image: theCategoryImageMap[category.key] || '',
      subItems: [
        ...category.items.map((item) => ({
          text: item.name,
          url: `/store/occasion/${item.slug}/`,
          category: theCategory,
        })),
      ],
      header: true,
    })
  })

  // Find the index of the last item with category "Featured"
  const lastIndex = occasionsMenuItems[0].subItems.reduceRight(
    (index, item, i) => {
      return item.category === 'Featured' && index === -1 ? i : index
    },
    -1
  )

  // Insert En Primeur after the last "Featured" item
  if (showEnPrimeur) {
    occasionsMenuItems[0].subItems.splice(lastIndex + 1, 0, {
      text: 'En Primeur',
      meta: ['en-primeur'],
      url: '/en-primeur/',
      category: 'Featured',
    })
  }

  return {
    text: 'Collections',
    image: 'mobilenav-collections',
    items: occasionsMenuItems,
  }
}
